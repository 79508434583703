<template>
  <div style="height: 713px" class="loading" v-if="selected_location === undefined && !location_error">
    <Loading />
  </div>
  <div class="not-found pt-200 pb-100" v-else-if="selected_location.data.length === 0">
    <h2 class="text-center">
      Not Found
    </h2>
  </div>
  <div
    class="about-section pt-200 pb-70 donate-custom-amount"
    id="donate-custom-amount"
    v-else
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12 contact-area">
          <div class="text-center">
            <h3 class="mb-1">Thank You For Donating to {{ selected_location.data[0].title }}</h3>
            <p class="mb-5" style="font-size: 17px">
              Enter your information and set your amount below
            </p>

            <div class="row align-items-start">
              <div class="col-md-6">
                <div class="pay-form contact-wrap">
                  <DonateForm
                    :custom="true"
                    :price="price"
                    :selected_location="selected_location"
                  />
                </div>
              </div>
              <div
                class="col-md-6 d-flex align-items-center justify-content-center"
              >
              <DefaultImage :id="selected_location.data[0].image" :width="'100%'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DonateForm from "../../components/DonateForm";
import fundraising from "./fundraising_data.json";
import useSWRV from "swrv";
import api from "../../services/api";
import Loading from "../../components/Loading";
import DefaultImage from "../../components/DefaultImage";

export default {
  name: "DonatePay",
  components: { DonateForm, Loading, DefaultImage },
  data() {
    return {
      id: null,
      fundraising_data: fundraising,
    };
  },
  setup(props, { root }) {
    const { price, location } = root.$route.params;

    const isPrice = price && !isNaN(price); 
    const resolvedPrice = isPrice ? parseFloat(price) : null;
    const resolvedLocation = isPrice ? (location || null) : price; 

    const filterOffice = resolvedLocation || 'Headquarters';

    let { data: selected_location, error: location_error } = useSWRV(
      () =>
        `items/locations?status=published&filter[title][in]=PeaceJam ${filterOffice}`,
      api.fetcher
    );

    return {
      selected_location,
      location_error,
      price: resolvedPrice,
    };
  }
  // methods: {
  //   checkMode() {
  //     return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY.includes("test");
  //   },
  // },
};
</script>

<style scoped lang="scss">
.test-mode-label {
  text-transform: uppercase;
  color: #25c6da;
  font-weight: 700;
  display: inline-flex;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 11px;
  background-color: #c4f3f8;
  line-height: 1;
  margin-left: 10px;
}
.pay-page {
  padding-top: 180px;
  overflow: hidden;

  .details-card {
    margin: 20px 0;
    h4 {
      font-weight: 600;
      font-size: 16px;
      color: rgba(26, 26, 26, 0.6);
      margin-bottom: 0;
    }
    h3 {
      font-size: 36px;
      margin: 2px 0 3px;
      font-variant-numeric: tabular-nums;
      letter-spacing: -0.03rem;
      font-weight: 700;
    }
  }
}
.pay-form {
  margin-bottom: 50px;

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: rgba(26, 26, 26, 0.9);
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

.design-element {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  width: 300px;
  height: 300px;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: -37px;
  top: -27px;
  opacity: 0.1;
  background: #867bf3;

  &.variant-2 {
    top: 71px;
    left: 400px;
    width: 600px;
    height: 600px;
    transform: rotate(81deg);
    background: #34bdd5;

    &:after {
      content: "";
      position: absolute;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
      top: -270px;
      left: -50px;
      width: 300px;
      height: 300px;
      background: #8c82f5;
    }
  }
}
</style>
