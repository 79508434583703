<template>
  <div style="height: 713px" class="loading" v-if="selected_location === undefined && !location_error">
    <Loading />
  </div>
  <div class="not-found pt-200 pb-100" v-else-if="selected_location.data.length === 0">
    <h2 class="text-center">
      Not Found
    </h2>
  </div>
  <div
    class="about-section pb-70 donate-custom-amount"
    id="donate-custom-amount"
    v-else
  >
    <!-- Start Page Title Area -->
    <div class="page-title-area donate-bg" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>Join The Points Guy and PeaceJam in Transforming Lives</h2>
          <ul>
            <li class="active">Make an Impact</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container pt-50">
      <div class="text-section">
        <div class="about-area-content">
          <span>The Points Guy</span>
        </div>
        <br>
        <div class="about-tab">
          <p class="white-space-pre-wrap">
            <p><span style="font-weight: 400;">Since 2015, The Points Guy, Brian Kelly, has partnered with PeaceJam to empower young leaders across the globe. Thanks to their generous support, PeaceJam has hosted transformational youth leadership conferences in Guatemala, Ghana, South Africa, and Liberia, directly impacting thousands of aspiring young changemakers.</span></p>
            <p><span style="font-weight: 400;">Under the mentorship of Nobel Peace Laureates like Rigoberta Menchú, Leymah Gbowee, and Tawakkol Karman, these high-energy events are the culmination of a year-round program that equips youth with the tools to lead with courage and compassion. Through partnerships with schools and educators, PeaceJammers develop six core competencies: personal power and purpose, inclusion, compassionate leadership, peacebuilding, global citizenship, and becoming agents of positive change.</span></p>
            <p>
            The impact is undeniable:
            <ul>
              <li>93% of PeaceJam participants believe that one person can make a difference.</li>

              <li>97% feel personally dedicated to being peacemakers for the rest of their lives.</li>
            </ul>
            </p>
            <p><span style="font-weight: 400;">Your donation will help PeaceJam expand its reach and amplify its impact, enabling more young people to overcome barriers to education, gain access to enriching programs, and build leadership skills that will transform their communities.</span></p>
            <p><span style="font-weight: 400;">Together, we can create a more peaceful, compassionate, and democratic future. Join us today in continuing this life-changing work.</span></p>
          </p>
        </div>
      </div>
    </div>

    <!-- End Page Title Area -->
    <div class="container pt-50">
      <div class="row">
        <div class="col-md-12 contact-area">
          <div class="text-center">
            <h3 class="mb-1">Thank you for being part of this journey with <br/> The Points Guy and PeaceJam!</h3>
            <p class="mb-5" style="font-size: 17px">
              Enter your information and set your amount below
            </p>

            <div class="row align-items-start">
              <div class="col-md-6">
                <div class="pay-form contact-wrap">
                  <DonateForm
                    :custom="true"
                    :price="price"
                    :selected_location="selected_location"
                    form='pointsguy'
                  />
                </div>
              </div>
              <div
                class="col-md-6 d-flex align-items-center justify-content-center"
              >
              <DefaultImage :id="selected_location.data[0].image" :width="'100%'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DonateForm from "../../components/DonateForm";
import fundraising from "../Donate/fundraising_data.json";
import useSWRV from "swrv";
import api from "../../services/api";
import Loading from "../../components/Loading";
import DefaultImage from "../../components/DefaultImage";

export default {
  name: "DonatePay",
  components: { DonateForm, Loading, DefaultImage },
  data() {
    return {
      id: null,
      fundraising_data: fundraising,
    };
  },
  setup(props, { root }) {
    const { price, location } = root.$route.params;

    const isPrice = price && !isNaN(price); 
    const resolvedPrice = isPrice ? parseFloat(price) : null;
    const resolvedLocation = isPrice ? (location || null) : price; 

    const filterOffice = resolvedLocation || 'Headquarters';

    let { data: selected_location, error: location_error } = useSWRV(
      () =>
        `items/locations?status=published&filter[title][in]=PeaceJam ${filterOffice}`,
      api.fetcher
    );

    return {
      selected_location,
      location_error,
      price: resolvedPrice,
    };
  }
  // methods: {
  //   checkMode() {
  //     return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY.includes("test");
  //   },
  // },
};
</script>

<style scoped lang="scss">
.test-mode-label {
  text-transform: uppercase;
  color: #25c6da;
  font-weight: 700;
  display: inline-flex;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 11px;
  background-color: #c4f3f8;
  line-height: 1;
  margin-left: 10px;
}
.pay-page {
  padding-top: 180px;
  overflow: hidden;

  .details-card {
    margin: 20px 0;
    h4 {
      font-weight: 600;
      font-size: 16px;
      color: rgba(26, 26, 26, 0.6);
      margin-bottom: 0;
    }
    h3 {
      font-size: 36px;
      margin: 2px 0 3px;
      font-variant-numeric: tabular-nums;
      letter-spacing: -0.03rem;
      font-weight: 700;
    }
  }
}
.pay-form {
  margin-bottom: 50px;

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: rgba(26, 26, 26, 0.9);
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

.design-element {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  width: 300px;
  height: 300px;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: -37px;
  top: -27px;
  opacity: 0.1;
  background: #867bf3;

  &.variant-2 {
    top: 71px;
    left: 400px;
    width: 600px;
    height: 600px;
    transform: rotate(81deg);
    background: #34bdd5;

    &:after {
      content: "";
      position: absolute;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
      top: -270px;
      left: -50px;
      width: 300px;
      height: 300px;
      background: #8c82f5;
    }
  }
}

.donate-bg {
    background-image: url("../../assets/img/Rigoberta_and_Brian_with_youth.jpeg");
    background-size: cover;
    background-position: center 67%;
    position: relative;

    >div {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #0000001f;
    }
  }
</style>
